
/* GLOBALS */


html, body, #root {
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  user-select: none;

  animation-delay: 0ms;

  height: 100%;



 /* night mode variables */

 --var-color-background:  #201E1F;
 --var-color-header-background:  #201E1F;

 --var-color-light-grey: #bdbdbd;

 --var-color-correct-green-01:  #538d4e;
 --var-color-correct-green-02:  #72ff90;
 --var-color-correct-green-03:  #062400;
 --var-color-correct-green-01-pattern-01:  #3d6939;

 --var-color-mistake-red-01:  #9e0000;
 --var-color-mistake-red-02:  #ff7b7b;
 --var-color-mistake-red-03:  #310000;
 --var-color-mistake-red-01-pattern-01:#6b0505;
 
 --var-color-keyboard-key-enabled:   #4a4a4a;
 --var-color-keyboard-key-enabled-pattern-01:   #363636;

 --var-color-medal-locked:  #28282800;
 --var-color-medal-locked-pattern-01:  #282828;

 --var-color-share-background:  #4c8247;

 --var-font-body: Roboto, sans-serif; 
 --var-font-size-lives: 35px;

 --var-speed-keycap-icon-hover-on: 150ms;
 --var-speed-keycap-icon-hover-off: 500ms;

 background-color: var( --var-color-background);
}



.App {
  text-align: center;
  background-color: var(--var-color-background);
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;


  font-family: var(--var-font-body);
}



.centerElements {

  width: 85%;
  max-width: 80vh;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  
}





/* HEADER */

.header {
  
  background-color: var(--var-color-header-background);

  display: block;

  border-bottom-color: #414040;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  padding-bottom: 30px;

}


.header-container {
  display: inline-block;
  width: 100%;

}

.header-center {

  position:absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
  
}


.header-left {
  opacity: 100%;
  position:absolute;
}


.header-right {
  position:absolute;
  opacity: 100%;
  left: 100%;
  transform: translate(-100%, 0);
}


.header-logo-container {
  width: 200px;
}


.header-logo {
  width: 100%;
}












/* HELP MENU */

.header-helpMenuButton {
  cursor: pointer;
  position: absolute;
  top: -7px;
  color:#ffffff;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  font-size: 25px;
  opacity:50%;
  transition: opacity 500ms;
  pointer-events: all;
}



.header-helpMenuButton:hover {
  opacity:100%;
  transition: opacity 50ms;
}




.header-medalsButton {
  cursor: pointer;
  pointer-events: all;

  width: 25px;
  height: 30px;
  display: grid;
  place-items: center;
  margin-right:10px;
  margin-top:-8.5px;
  font-size: 15px;
  font-weight: 700;
  vertical-align: middle;
  opacity:50%;

  border-style: solid;
  border-radius: 10px;
  border-width: 2px;

  /*border: solid 1px #000 ;*/
  overflow:hidden;
  outline-style: none;

  animation:  rainbow-anim-colorChange 10000ms infinite, rainbow-anim-colorChange-border 10000ms infinite;

  
  transition: opacity 500ms;

} 


.header-medalsButton:hover {
  opacity:100%;
  transition: opacity 50ms;
}


.header-medalsButton-symbol {
  padding-bottom:2px;
  border-radius: 100%;

}

.helpMenu-fader {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
  pointer-events: none;
  background-color: #201e1f00;

  transition: background-color 1s;
}


.helpMenu-fader-active {
  background-color: #201e1fde;
  transition: background-color 500ms;
  pointer-events: all;
}

.helpMenu-container-outer {
  animation: helpMenu-container-outer-appear 2;
}

@keyframes helpMenu-container-outer-appear {
  0% { transform:  translate(0px, -100px); }
  100% { transform:  translate(0px, 0px); }
}


.helpMenu-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  margin: auto;

  background-color: #201e1f;
  
  font-family: 'Zen Kaku Gothic Antique', sans-serif; 

}






.helpMenu-contents {
  position:absolute;
  word-wrap: break-word;
  overflow: auto;
  max-height: 100%;
  width: 100%;
  letter-spacing: 0.5px;
}



.helpMenu-guide {
  font-size: 20px;
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 20px;

  
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  padding-bottom:30px;

}


.helpMenu-socials {
  display: inline-block;
  border-spacing: 15px 0px;
}
.helpMenu-discordButton {
  cursor: pointer;
  color:#ffffff;
  width: 30px;
  height: 30px;
  opacity:50%;
  transition: opacity 500ms;
  pointer-events: all;
  display: inline-block;
  margin-left:50%;
  padding-top: 15px;

  filter: brightness(0) invert(1);
  transform: translate(-50%, 0) scale(1.2);
  
}


.helpMenu-discordButton:hover {
  opacity:100%;
}


.instagramButton {


  cursor: pointer;
  color:#ffffff;
  width: 30px;
  height: 30px;
  opacity:50%;
  transition: opacity 500ms;
  pointer-events: all;
  display: inline-block;
  margin-left:50%;
  padding-top: 15px;

  filter: brightness(0) invert(1);
  transform: translate(-50%, 0) scale(1.2);
}


.instagramButton:hover {
  opacity:100%;
}






.helpMenu-credit {

  margin-left: 25%;
  width: 50%;
}

.helpMenu-credits-header {

  cursor: pointer;

  text-align: left;
  font-size: 20px;
  white-space: nowrap;

  border-bottom-color: #414040;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  padding-top: 20px;
  padding-bottom: 20px;
}


.helpMenu-credits-body {
  text-align: left;
}



.image-clue {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  flex-direction: column;
  padding-bottom:30px;
}


.image-clue-subtitle {
  text-align: center;
}

.words {
  display: inline-flex;
  gap: 20px;
  line-height: 30px;
  justify-content: center;
}

.word {
  line-height: 30px;
}

.helpMenu-carousel-container {
  
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  flex-direction: column;
  margin-left: 50%;
  transform: translate(-50%, 0);  
}


.helpMenu-carousel-image {
  border-radius: 10px;
}

a.helpMenu-hyperlink {
  color:inherit;
  text-decoration: none;
  opacity: 70%;
  transition: opacity 500ms;
}

a.helpMenu-hyperlink:hover {
  opacity: 100%;
  transition: opacity 20ms;
}

#twitter {
  color:rgb(0, 183, 255);
}

#email {
  color:rgb(255, 86, 86);
}



a.helpMenu-link-twitter {
  color:rgb(0, 183, 255);
  padding:0px 5px 0px 5px;

  text-decoration-color:  rgb(0, 183, 255, 0.5);
  text-decoration-style: dotted;
  text-decoration-thickness: 3px;
}


::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  background: #00000000; 
}
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0); 
}




.slick-dots li button:before,
.slick-dots li.slick-active button:before {
  color: white
 }


.simple-closeButton-container {
  position:absolute;
  left: 100%;
  top:0%;

  transform: translate(-100%, 0);
 }



.simple-closeButton {

  position:relative;
  right: 10px;




  display: grid;
  place-items: center;

  cursor: pointer;
  

 /* margin-right: 5px;*/
  margin-top: 5px;
  
  width: 40px;
  height: 40px;

  opacity:50%;
  transition: opacity 500ms;
  pointer-events: all;
  transform: scale(1.25);
}




.simple-closeButton:hover {
  opacity:100%;
  transition: opacity 50ms;
}

/* SUBMENU */

.subMenu-header {
  width: 100%;
  height: 35px;
  padding-top:15px;
  white-space: nowrap;

  
  border-bottom-color: #414040;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  
  font-weight: 700;
  font-size: 20px;
}

.subMenu-left {
  position:absolute;
}

.subMenu-center {
  position:absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0);
}

.subMenu-right {
  position:absolute;
  top: -10px;
  left: 100%;
  transform: translate(-100%, 0);

}


.subMenu-footer {
  position:absolute;
  width: 100%;
  bottom: 10px;
  left: 100%;
  transform: translate(-100%, 0);

  padding-top:15px;
  padding-bottom:5px;
  
  border-top-color: #414040;
  border-top-width: 1px;
  border-top-style: solid;
}


/* MEDALS */


.medals-fader {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
  pointer-events: none;
 background-color: #201e1f00;

  transition: background-color 500ms;
}


.medals-fader-active {
   background-color: #201e1fde;
  transition: background-color 0ms;
  pointer-events: all;
}

.medals-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 90%;
  height: 80%;
  margin: auto;

  border-style: solid;
  border-color: var(--var-color-keyboard-key-enabled);
  border-radius: 20px;

  background-color: #201e1f;

  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 30px;
}


.medals-shelf-container {

}

.medals-shelf {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;
  
  
}

.medals-footer {
  
  font-family: var(--var-font-body);
  font-weight: 500;
  font-size: 16px;
  padding-left: 5px;
  padding-right: 5px;
}

.medals-item {

  /*width: 10%;*/
  border-radius: 15%;

  aspect-ratio: 11/16;
  
  display: flex;
  align-items: center;
  text-align: center;
  font-family: var(--var-font-body);
  font-size: 20px;
  font-weight: 700;

  /*
  margin: 2px;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 700;

  aspect-ratio: 10/16;*/
  

}

.medals-item-locked {
  background-color: var(--var-color-medal-locked);
  opacity: 100%;
  font-size: 14px;
  color: rgba(255, 255, 255, var(--var-locked-item-opacity));
  overflow-wrap:break-word;
  word-wrap: break-word;


  background-image: linear-gradient(var(--var-color-medal-locked), var(--var-color-medal-locked-pattern-01));

}


.medals-item-icon {
  width: 100%;
}


.medals-item-selected {
  animation: medals-item-selected-inner 1000ms infinite;

}


@keyframes medals-item-selected-inner {

  0% {
    filter: brightness(110%);
  }

  50% {
    filter: brightness(160%);
  }

  
  100% {
    filter: brightness(110%);
  }
}


 
.medals-item {
  width: 50px;
}

@media only screen and (min-width: 700px) {
  .medals-item {
    width: 70px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 1000px) {
  .medals-item {
    width: 80px;
  }
}

@media only screen and (min-width: 1500px) {
  .medals-item {
    width: 100px;
  }
}

@media only screen and (min-width: 2000px) {
  .medals-item {
    width: 140px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 2300px) {
  .medals-item {
    width: 180px;
  }
}


/* UPDATE NOTIFICATION */



.update-notification-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 80%;
  height: 40%;
  margin: auto;

  border-style: solid;
  border-color: var(--var-color-keyboard-key-enabled);
  border-radius: 20px;

  background-color: #201e1f;

  overflow-x: hidden;
  overflow-y: scroll;
  font-size: 30px;
}

.update-notification-fader {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
  pointer-events: none;
 background-color: #201e1f00;

  transition: background-color 500ms;
}


.update-notification-fader-active {
   background-color: #201e1fed;
  transition: background-color 500ms;
  pointer-events: all;
}


.update-notification-notes {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: var(--var-font-body);
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  line-height: 28px;


}


/* FOOTER */

.footer {
  text-align: center;
  font-size: clamp(2px, 1vw, 22px);
  
  background-color: var(--var-color-background);
  font-weight: 500;
  font-size: 12px;
  color: var(--var-color-light-grey);
  padding-bottom:10px;
  padding-top:2px;
  
}



a.footer-hyperlink {
  color:inherit;
  opacity: 70%;
  
  text-decoration: none;
  text-decoration-color:  rgb(255, 100, 100, 0.5);
  transition: opacity 500ms;
}

a.footer-hyperlink:hover {
  opacity: 100%;
  transition: opacity 20ms;
}



/* STREAK */



.streakBar-container {
  display: inline-block;
  width: 100%;
  height: 50px;

}

.streakBar-center {
  position:absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0);
  
}

.streakBar-left {
  position:absolute;
  width: 200px;
  text-align: left;
}

.streakBar-right {
  position:absolute;
  left: 100%;
  transform: translate(-100%, 0);
  text-align: right;
  width: 200px;
}






.streak {
  margin-top: 15px;
  position: relative;
  font-size: 15px;
  font-weight: 700;
}



.aliveStreak {
  top: 0px;
  text-align: left;
  color: white; /* var(--var-color-correct-green-02);*/
  display: inline;
  vertical-align: middle;
}

.aliveStreakDull {
  color: #9a9a9a;
}

.superStreak {
  text-align: right;
  color: #a99256;
  display: inline;
  vertical-align: middle;
}

.superStreakHighScore-01 {
  padding: 0px;
  animation: rainbow-anim-colorChange 2000ms infinite;
  animation-delay: 0ms;
}
.superStreakHighScore-02 {
  padding: 0px;
  animation: rainbow-anim-colorChange 2000ms infinite;
  animation-delay: 200ms;
}
.superStreakHighScore-03 {
  padding: 0px;
  animation: rainbow-anim-colorChange 2000ms infinite;
  animation-delay: 400ms;
}
.superStreakHighScore-04 {
  padding: 0px;
  animation: rainbow-anim-colorChange 2000ms infinite;
  animation-delay: 600ms;
}
.superStreakHighScore-05 {
  padding: 0px;
  animation: rainbow-anim-colorChange 2000ms infinite;
  animation-delay: 800ms;
}
.superStreakHighScore-06 {
  padding: 0px;
  animation: rainbow-anim-colorChange 2000ms infinite;
  animation-delay: 1000ms;
}
.superStreakHighScore-07 {
  padding: 0px;
  animation: rainbow-anim-colorChange 2000ms infinite;
  animation-delay: 1200ms;
}
.superStreakHighScore-08 {
  padding: 0px;
  animation: rainbow-anim-colorChange 2000ms infinite;
  animation-delay: 1400ms;
}
.superStreakHighScore-09 {
  padding: 0px;
  animation: rainbow-anim-colorChange 2000ms infinite;
  animation-delay: 1600ms;
}
.superStreakHighScore-10 {
  padding: 0px;
  animation: rainbow-anim-colorChange 2000ms infinite;
  animation-delay: 1800ms;
}


.superStreakInvisible {
  padding-top: 4px;
  padding-bottom: 15px;
  padding-right: 10px;
  text-align: right;
  visibility: hidden;
}

@keyframes rainbow-anim-colorChange {

  0% {
    color: #ff9a81;
  }

  25% {
    color: #fffc44;
  }

  50% {
    color: #92ff7f;
  }

  75% {
    color: #7fecff;
  }

  100% {
    color: #ff9a81;
  }
}


@keyframes rainbow-anim-colorChange-border {

  0% {
    border-color: #ff9a81;
  }

  25% {
    border-color: #fffc44;
  }

  50% {
    border-color: #92ff7f;
  }

  75% {
    border-color: #7fecff;
  }

  100% {
    border-color: #ff9a81;
  }
}




/*
LIFE BAR
*/

.lives {

  font-size: var(--var-font-size-lives);
  vertical-align: middle;

  position: relative;
  bottom: 12px;
  letter-spacing: 5px;
  white-space: nowrap;


}

.lives-life-completed {
  animation: lives-life-completed-anim 1000ms 1;



}


@keyframes lives-life-completed-anim {

  0% {
    -webkit-filter: sepia(0%) brightness(100%);
    filter: sepia(0%) brightness(100%);
  }

  10% {
    -webkit-filter: sepia(40%) brightness(600%);
    filter: sepia(40%) brightness(600%);
  }

  100% {
    -webkit-filter: sepia(0%) brightness(100%);
    filter: sepia(0%) brightness(100%);
  }
}




.lives-hurt-1 {
  animation: shake-1 3 0.15s;
}
.lives-hurt-2 {
  animation: shake-2 3 0.15s;
}
.lives-hurt-3 {
  animation: shake-3 3 0.15s; 
}




/* IMAGE_CLUE */


.imageClueContainer {
  /*width: 100%;*/
  margin-top:10px;
  border-radius: 15px;
  pointer-events: none;

  overflow: hidden;
  
  aspect-ratio: 16 / 9;
}

.imageClueImage1 {
  margin-left:-5%;
  margin-top:-5%;
  width:220%;
  height: 220%;
  animation: imageClueImage-reveal1 0.7s 1;

 /* transition:margin-left 300ms, margin-top 300ms;*/
}

.imageClueImage2 {
  margin-left:-115%;
  margin-top:-5%;
  width:220%;
  height: 220%;
  animation: imageClueImage-reveal2 0.7s 1;
}

.imageClueImage3 {
  margin-left:-5%;
  margin-top:-65%;
  width:220%;
  height: 220%;
  animation: imageClueImage-reveal3 0.7s 1;
}

.imageClueImage4 {
  margin-left:-115%;
  margin-top:-65%;
  width:220%;
  height: 220%;

  animation: imageClueImage-reveal4 0.7s 1;
}




@keyframes imageClueImage-reveal1 {
  0% {opacity: 70%;}
  100% { opacity: 100%;}
}
@keyframes imageClueImage-reveal2 {
  0% {opacity: 70%;}
  100% { opacity: 100%;}
}
@keyframes imageClueImage-reveal3 {
  0% {opacity: 70%;}
  100% { opacity: 100%;}
}
@keyframes imageClueImage-reveal4 {
  0% {opacity: 70%;}
  100% { opacity: 100%;}
}



.imageClueImage-dots {
  padding-top: 15px;
  pointer-events: all;
  
}
.imageClueImage-dot {
  background-color: #eee;
  border-radius: 100%;
  opacity: 50%;
  height: 15px;
  width: 15px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: opacity 300ms;
}
.imageClueImage-dot.active {
  background-color: #ffffff;
  opacity: 100%;
  transition: opacity 300ms;
}
















/*
HANGMAN
*/

.hangman {
  max-width: 500px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.hangman-line {


  padding-bottom: 10px;


  transition: background-color 300ms, border-color 300ms;
  line-height: 10px;

  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  


}


.hangman-line-gameOver {
  background-color: #00000000;

  border-color: rgba(255, 255, 255, 0);
}


.hangmanLetter {
  font-size: 20px;
  letter-spacing: 4px;
  word-spacing: 10px;
  color: #FFFFFF;
  font-weight: 700;
  position: relative;
  transition: color 1s;
}


@media only screen and (min-width: 500px) {
  .hangmanLetter {
    font-size: 30px;
  }
}




.hangmanLetter-revealed {
  animation: hangmanLetter-revealed-anim 0.7s 1;
  display: inline-block;
}


@keyframes hangmanLetter-revealed-anim {

  0% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;

  }

  25% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;

  }

  100% {

    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 1;

  }
}


.hangmanLetter-gameOver-mistake {
  color:  var(--var-color-mistake-red-02); 
}

.hangmanLetter-secret-correct {
  color:  var(--var-color-correct-green-02);
  transition: color 300ms;
}

.hangmanLetter-gameOver-correct {
  color:  var(--var-color-correct-green-02);
}

.hangmanLetter-hidden {
  opacity: 35%;
}


.hangmanLetter-hint {
  opacity: 10%;
}





/*
GAME
*/

.game {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

}


/*
KEYBOARD
*/

.keyboard {
  transition: background-color 500ms, opacity 1s;
  padding-top: 10px;
  padding-bottom:10px;

  
}

.keyboard-gameOver {
  opacity: 0;
}



.keyboard-line {
  flex: 33%;
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin: 5px;

  
}

.keyboardKey {
  width: 50px;
  max-width: 10%;
  height: 60px;
  margin: 2px;
  border-radius: 6px;
  display: grid;
  place-items: center;
  font-size: 20px;
  font-weight: 700;
  transition: opacity 200ms;

  

} 



.keyboardKey-enabled {
  /*color: white;*/
  background: var(--var-color-keyboard-key-enabled);
  transition: transform 50ms;/*, opacity 800ms;*/
  cursor: pointer;  
}

.keyboardKey-enabled:hover {
  filter:brightness(150%);
}

.keyboardKey-enabled:active {
  opacity: 100%;
  transform: translateY(2px);
}

.keyboardKey-waiting-for-input {
  background: #9a9a9a;
  opacity: 50%;
}

.keyboardKey-disabled {
  background: #777777;
  opacity: 20%;
}



.keyboardKey-good-hint {
  /*transform: translateY(-8px);*/
  outline: var(--var-color-correct-green-01);
  outline-width: 2px;
  outline-style: dotted;
}

.keyboardKey-bad-hint {
  /*transform: translateY(-8px);*/
  outline: var(--var-color-mistake-red-01);
  outline-width: 2px;
  outline-style: dotted;
  filter: opacity(25%);
}


/*
.keyboardKey-mistake {
  background: var(--var-color-mistake-red-01); 
  opacity: 100%;
}


.keyboardKey-correct {
  background: var(--var-color-correct-green-01);
  opacity: 100%;
}*/





.keyboardKey-selected {
  animation: keyboardKey-selected-anim 0.3s 1;

}


@keyframes keyboardKey-selected-anim {

  0% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 100%;

  }

  25% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 100%;
    
  filter:brightness(150%);
  

  }

  100% {

    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 100%;
  }
}






.rating {
  
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 2px;
  padding-top: 5px;
  line-height: 35px;
  color: var(--var-color-correct-green-02);
}



.rating-superStreak {
 /* animation: superStreak-anim-colorChange 1000ms infinite;*/
}



@keyframes rating-superStreak-anim-colorChange  {

  0% {
    color: var(--var-color-correct-green-02);
  }
  50% {
    color: #ffffff;
  }
  100% {
    color: var(--var-color-correct-green-02);
  }
}


/*
 COUNTDOWN
*/


.countdown {
  
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 2px;
  padding-top: 15px;
   line-height: 27px;  /* regular */
  color: var(--var-color-light-grey);
  text-decoration: none;


 /* padding-bottom: 20px;*/ /* announcement */
  padding-bottom: 40px; /* regular */
}

.countdown-announcement {
  opacity: 50%;
  font-size: 15px;
}

.countdown-sub {
  padding-bottom: 10px;
}

.countdownCounter {
  font-weight: 700;
  font-size: 30px;
}
.countdownCounter a {
  color: var(--var-color-light-grey);
  text-decoration: none; 
  transition: opacity 100ms;
  
}
.countdownCounter a:hover {
  color: white;
  transition: opacity 100ms;
  
}



/*
 SHARE BUTTON
*/


.shareButton-outerContainer {
  padding-left: 50%;
  padding-bottom:30px;

}

.shareButton-container {
  transform: translate(-50%, 0);

  cursor: pointer;


  background-color: var(--var-color-share-background);
  color:#ffffff;
  
  border-radius: 15px;
  border-color: rgb(255, 255, 255);
  border-width: 2px;
  border-style: solid;


  width:fit-content;
  height: 50px;

  opacity:100%;
  transition: opacity 500ms;
  pointer-events: all;


  text-align: center;
  vertical-align: middle;
  
  font-size: 25px;
  font-weight: 700;

  overflow:hidden;

  display: flex;
  justify-content: center;
  align-items: center; 
  padding-right: 20px;
}


.shareButton-container:hover {
  filter: brightness(120%);
}



.shareButton-icon {
  height:100%;
  width:auto;
}


.shareButton-validation-container {
  position: relative;
  padding-left: 50%;

}

.shareButton-validation {
  position: absolute;
  transform: translate(-50%, -35px);


  text-align: center;



  font-size: 16px;
  font-weight: 700;
  opacity:0%;
  letter-spacing: 3px;

  
  transition: opacity 300ms;
}

.shareButton-validation-appear {
  opacity:100%;

  transition: opacity 50ms;

}

.slick-list {
  border-radius: 20px;
}


@media only screen and (max-width: 600px) {

  .keyboardKey {
    
    height: 48px;
  }

  .imageClueImage-dots {
    padding-top: 0px;
  }

  .imageClueImage-dot {
    width: 7px;
    height: 7px;
    margin: 0px 3px
  }

  


}


@media only screen and (min-width: 600px) {

  .shareButton-validation {
    font-size: 20px;
  }
}


@media only screen and (max-width: 1000px) {

  .helpMenu-carousel-container {
    width: 100%;
  }
}


/*
HELPER
*/

@keyframes shake-1 {
  0% { transform:  translate(0px, 0px); }
  25% { transform:  translate(-8px, 0px); }
  75% { transform:  translate(8px, 0px); }
  100% { transform:  translate(0px, 0px); }
}

@keyframes shake-2 {
  0% { transform:  translate(0px, 0px); }
  25% { transform:  translate(-9px, 0px); }
  75% { transform:  translate(9px, 0px); }
  100% { transform:  translate(0px, 0px); }
}

@keyframes shake-3 {
  0% { transform:  translate(0px, 0px); }
  25% { transform:  translate(-10px, 0px); }
  75% { transform:  translate(10px, 0px); }
  100% { transform:  translate(0px, 0px); }
}


.selectable {
  cursor: pointer;
}


/*
UNLOCKABLES
*/


/* NONE */

.unlockable-64528-keyboardCap-enabled {
  animation: unlockable-64528-letters-appear 500ms;
  animation-fill-mode: forwards;
  opacity: 0%;
  background: var(--var-color-keyboard-key-enabled);
}

.unlockable-64528-keyboardCap-icon {
  background: var(--var-color-keyboard-key-enabled);
  transition: filter var(--var-speed-keycap-icon-hover-off);
}
.unlockable-64528-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}

.unlockable-64528-keyboardCap-mistake {
  background: var(--var-color-mistake-red-01);
}

.unlockable-64528-keyboardCap-correct {
  background: var(--var-color-correct-green-01);
}

@keyframes unlockable-64528-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}


/* STRIPES */

.unlockable-125467-keyboardCap-enabled {

  animation: unlockable-125467-letters-appear  500ms;
  opacity: 0%;
  animation-fill-mode: forwards;

  background: radial-gradient(27px at center, var(--var-color-keyboard-key-enabled), #00000000), 
  repeating-linear-gradient(
  45deg,
  var(--var-color-keyboard-key-enabled),
  var(--var-color-keyboard-key-enabled) 10px,
  var(--var-color-keyboard-key-enabled-pattern-01) 10px,
  var(--var-color-keyboard-key-enabled-pattern-01) 20px
  );
}

.unlockable-125467-keyboardCap-icon {
  transition: filter var(--var-speed-keycap-icon-hover-off);
  background: radial-gradient(27px at center, var(--var-color-keyboard-key-enabled), #00000000), 
  repeating-linear-gradient(
  45deg,
  var(--var-color-keyboard-key-enabled),
  var(--var-color-keyboard-key-enabled) 10px,
  var(--var-color-keyboard-key-enabled-pattern-01) 10px,
  var(--var-color-keyboard-key-enabled-pattern-01) 20px
  );
}
.unlockable-125467-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}

.unlockable-125467-keyboardCap-correct {
  background: radial-gradient(27px at center, var(--var-color-correct-green-01), #00000000), 
  repeating-linear-gradient(
  45deg,
  var(--var-color-correct-green-01),
  var(--var-color-correct-green-01) 10px,
  var(--var-color-correct-green-01-pattern-01) 10px,
  var(--var-color-correct-green-01-pattern-01) 20px
  );
}

.unlockable-125467-keyboardCap-mistake {
  background: radial-gradient(27px at center, var(--var-color-mistake-red-01), #00000000), 
  repeating-linear-gradient(
  -45deg,
  var(--var-color-mistake-red-01),
  var(--var-color-mistake-red-01) 10px,
  var(--var-color-mistake-red-01-pattern-01) 10px,
  var(--var-color-mistake-red-01-pattern-01) 20px
  );
}

@keyframes unlockable-125467-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}






/* DOTS */

.unlockable-45218-keyboardCap-enabled {

  animation: unlockable-45218-letters-appear  500ms;
  opacity: 0%;
  animation-fill-mode: forwards;

  background-color: var(--var-color-keyboard-key-enabled-pattern-01);
  background-image: 
  radial-gradient(var(--var-color-keyboard-key-enabled) 20%, transparent 11%),
  radial-gradient(var(--var-color-keyboard-key-enabled) 20%, transparent 11%);
  background-size: 20px 20px;
  background-position: 0 0, 30px 30px;
}

.unlockable-45218-keyboardCap-icon {
  transition: filter var(--var-speed-keycap-icon-hover-off);
  background-color: var(--var-color-keyboard-key-enabled-pattern-01);
  background-image: 
  radial-gradient(var(--var-color-keyboard-key-enabled) 20%, transparent 11%),
  radial-gradient(var(--var-color-keyboard-key-enabled) 20%, transparent 11%);
  background-size: 20px 20px;
  background-position: 0 0, 30px 30px;
}
.unlockable-45218-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}
 
.unlockable-45218-keyboardCap-correct {
  background-color: var(--var-color-correct-green-01-pattern-01);
  background-image: 
  radial-gradient(var(--var-color-correct-green-01) 40%, transparent 11%),
  radial-gradient(var(--var-color-correct-green-01) 40%, transparent 11%);
  background-size: 20px 20px;
  background-position: 0 0, 30px 30px;
}
 
.unlockable-45218-keyboardCap-mistake {
  background-color: var(--var-color-mistake-red-01);
  background-image: 
  radial-gradient(var(--var-color-mistake-red-01-pattern-01) 50%, transparent 11%),
  radial-gradient(var(--var-color-mistake-red-01-pattern-01) 50%, transparent 11%);
  background-size: 20px 20px;
  background-position: 0 0, 30px 30px;
}

@keyframes unlockable-45218-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}




/* GOOGLY EYES */

.unlockable-766423-keyboardCap-enabled {


  animation: unlockable-766423-letters-appear  500ms;
  opacity: 0%;
  animation-fill-mode: forwards;

  background-color: var(--var-color-keyboard-key-enabled);


  background-image: 
  radial-gradient(80% 80% at 50% 50%, var(--var-color-keyboard-key-enabled-pattern-01) 35%, transparent 0), 
  radial-gradient(150% 70% at 50% 50%, var(--var-color-keyboard-key-enabled) 35%, transparent 0),
  radial-gradient(300% 300% at 50% 50%, var(--var-color-keyboard-key-enabled-pattern-01) 35%, transparent 0);
}


.unlockable-766423-keyboardCap-icon {
  transition: filter var(--var-speed-keycap-icon-hover-off);
  background-color: var(--var-color-keyboard-key-enabled);
  background-image: 
  radial-gradient(80% 80% at 50% 50%, var(--var-color-keyboard-key-enabled-pattern-01) 35%, transparent 0), 
  radial-gradient(150% 70% at 50% 50%, var(--var-color-keyboard-key-enabled) 35%, transparent 0),
  radial-gradient(300% 300% at 50% 50%, var(--var-color-keyboard-key-enabled-pattern-01) 35%, transparent 0);
}

.unlockable-766423-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}

 
.unlockable-766423-keyboardCap-correct {
  background-color: var(--var-color-correct-green-01);
  background-image: 
  radial-gradient(70% 70% at 50% 50%, var(--var-color-correct-green-01-pattern-01) 35%, transparent 0),
  radial-gradient(150% 100% at 50% 50%, var(--var-color-correct-green-01) 35%, transparent 0),
  radial-gradient(300% 300% at 50% 50%, var(--var-color-correct-green-01-pattern-01) 35%, transparent 0);
  
  
}
 
.unlockable-766423-keyboardCap-mistake {
  
  background-color: var(--var-color-mistake-red-01);
  background-image: 
  radial-gradient(70% 70% at 50% 50%, var(--var-color-mistake-red-01-pattern-01) 35%, transparent 0), 
  radial-gradient(150% 20% at 50% 50%, var(--var-color-mistake-red-01) 35%, transparent 0),
  radial-gradient(300% 300% at 50% 50%, var(--var-color-mistake-red-01-pattern-01) 35%, transparent 0);
}

@keyframes unlockable-766423-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}



/* GRAD */


.unlockable-98642-keyboardCap-enabled {
  animation: unlockable-98642-letters-appear  500ms;
  opacity: 0%;
  animation-fill-mode: forwards;
  background-color: var(--var-color-keyboard-key-enabled);
  background-image: linear-gradient(var(--var-color-keyboard-key-enabled), var(--var-color-keyboard-key-enabled-pattern-01));
}
.unlockable-98642-keyboardCap-icon {
  transition: filter var(--var-speed-keycap-icon-hover-off);
  background-color: var(--var-color-keyboard-key-enabled);
  background-image: linear-gradient(var(--var-color-keyboard-key-enabled) 30%, var(--var-color-keyboard-key-enabled-pattern-01) 70%);
}
.unlockable-98642-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}
 
 
.unlockable-98642-keyboardCap-correct {
  background-color: var(--var-color-correct-green-01);
  background-image:  linear-gradient(var(--var-color-correct-green-01-pattern-01), var(--var-color-correct-green-01));
}
 
.unlockable-98642-keyboardCap-mistake {
  background-color: var(--var-color-mistake-red-01);
  background-image:  linear-gradient(var(--var-color-mistake-red-01-pattern-01), var(--var-color-mistake-red-01));
}

@keyframes unlockable-98642-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}


/* HEART */

.unlockable-444678-keyboardCap-enabled {
  animation: unlockable-444678-letters-appear  500ms;
  opacity: 0%;
  animation-fill-mode: forwards;

  background: #36363684;
  background-image: url('images/skins/heart-enabled.svg');
  background-position: 50% 60%;
  background-size: 90%;
  background-repeat: no-repeat;
}
.unlockable-444678-keyboardCap-icon {

  transition: filter var(--var-speed-keycap-icon-hover-off);
  background: #36363684;
  background-image: url('images/skins/heart-enabled.svg');
  background-position: 50% 60%;
  background-size: 90%;
  background-repeat: no-repeat;
}
.unlockable-444678-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}
 
.unlockable-444678-keyboardCap-correct {

  background: #3d693984;
  background-image: url('images/skins/heart-correct.svg');
  background-position: 50% 60%;
  background-size: 90%;
  background-repeat: no-repeat;


}
 
.unlockable-444678-keyboardCap-mistake {
  background: #6b050584;
  background-image: url('images/skins/heart-mistake.svg');
  background-position: 50% 60%;
  background-size: 90%;
  background-repeat: no-repeat;
}

@keyframes unlockable-444678-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}









/* CAT */

.unlockable-333875-keyboardCap-enabled {
  animation: unlockable-333875-letters-appear  500ms;
  opacity: 0%;
  animation-fill-mode: forwards;

  background: rgba(0, 0, 0, 0);
  background-image: url('images/skins/cat-enabled.svg');
  background-size: 100%, 100%;

}
 
.unlockable-333875-keyboardCap-icon {
  transition: filter var(--var-speed-keycap-icon-hover-off);
  background: rgba(0, 0, 0, 0);
  background-image: url('images/skins/cat-enabled.svg');
  background-size: 100%, 100%;

}
.unlockable-333875-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}

.unlockable-333875-keyboardCap-correct {
  background: rgba(0, 0, 0, 0);
  background-image: url('images/skins/cat-correct.svg');
  background-size: 100%, 100%;
  background-repeat: no-repeat;
}
 
.unlockable-333875-keyboardCap-mistake {

  background: rgba(0, 0, 0, 0);
  background-image: url('images/skins/cat-mistake.svg');
  background-size: 100%, 100%;
  background-repeat: no-repeat;
}

@keyframes unlockable-333875-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}




/* BOLT */


.unlockable-2244221-keyboardCap-enabled {
  animation: unlockable-2244221-letters-appear  500ms;
  opacity: 0%;
  animation-fill-mode: forwards;
  background-color: var(--var-color-keyboard-key-enabled);
  

  background-image:
  radial-gradient(12px 12px at 20% 20%, var(--var-color-keyboard-key-enabled) 35%, transparent 0),
  radial-gradient(20px 20px at 20% 20%, var(--var-color-keyboard-key-enabled-pattern-01) 35%, transparent 0),
  radial-gradient(12px 12px at 80% 20%, var(--var-color-keyboard-key-enabled) 35%, transparent 0),
  radial-gradient(20px 20px at 80% 20%, var(--var-color-keyboard-key-enabled-pattern-01) 35%, transparent 0);

}

.unlockable-2244221-keyboardCap-icon {
  transition: filter var(--var-speed-keycap-icon-hover-off);
  background-color: var(--var-color-keyboard-key-enabled);
  
  background-image:
  radial-gradient(12px 12px at 20% 20%, var(--var-color-keyboard-key-enabled) 35%, transparent 0),
  radial-gradient(20px 20px at 20% 20%, var(--var-color-keyboard-key-enabled-pattern-01) 35%, transparent 0),
  radial-gradient(12px 12px at 80% 20%, var(--var-color-keyboard-key-enabled) 35%, transparent 0),
  radial-gradient(20px 20px at 80% 20%, var(--var-color-keyboard-key-enabled-pattern-01) 35%, transparent 0);

}
.unlockable-2244221-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}
 
.unlockable-2244221-keyboardCap-correct {
  background-color: var(--var-color-correct-green-01);
  background-image:
  radial-gradient(12px 12px at 20% 20%, var(--var-color-correct-green-01) 35%, transparent 0),
  radial-gradient(20px 20px at 20% 20%, var(--var-color-correct-green-01-pattern-01) 35%, transparent 0),
  radial-gradient(12px 12px at 80% 20%, var(--var-color-correct-green-01) 35%, transparent 0),
  radial-gradient(20px 20px at 80% 20%, var(--var-color-correct-green-01-pattern-01) 35%, transparent 0),
  radial-gradient(12px 12px at 20% 80%, var(--var-color-correct-green-01) 35%, transparent 0),
  radial-gradient(20px 20px at 20% 80%, var(--var-color-correct-green-01-pattern-01) 35%, transparent 0),
  radial-gradient(12px 12px at 80% 80%, var(--var-color-correct-green-01) 35%, transparent 0),
  radial-gradient(20px 20px at 80% 80%, var(--var-color-correct-green-01-pattern-01) 35%, transparent 0)
  
  
  ;
}
 
.unlockable-2244221-keyboardCap-mistake {
  
  background-color: var(--var-color-mistake-red-01);
  background-image:
  radial-gradient(12px 12px at 20% 20%, var(--var-color-mistake-red-01) 35%, transparent 0),
  radial-gradient(20px 20px at 20% 20%, var(--var-color-mistake-red-01-pattern-01) 35%, transparent 0),
  radial-gradient(20px 20px at 80% 20%, var(--var-color-mistake-red-01-pattern-01) 35%, transparent 0);
  

  transform: rotate(10deg) translate(-2px, 3px);
  
}

@keyframes unlockable-2244221-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}


/* DIAMONDS */


.unlockable-777765-keyboardCap-enabled {
  animation: unlockable-777765-letters-appear  500ms;
  opacity: 0%;
  animation-fill-mode: forwards;

  
  background-color: var(--var-color-keyboard-key-enabled);

  background-image:
  radial-gradient(27px at center, var(--var-color-keyboard-key-enabled), #00000000), 
  repeating-linear-gradient(120deg, var(--var-color-keyboard-key-enabled-pattern-01),  
  var(--var-color-keyboard-key-enabled-pattern-01) 1px, 
  transparent 15px, 
  transparent 22px),
  repeating-linear-gradient(60deg,  var(--var-color-keyboard-key-enabled-pattern-01),  
  var(--var-color-keyboard-key-enabled-pattern-01) 1px, 
  transparent 15px, 
  transparent 22px);
} 


.unlockable-777765-keyboardCap-icon {
  transition: filter var(--var-speed-keycap-icon-hover-off);
  background-color: var(--var-color-keyboard-key-enabled);

  background-image:
  radial-gradient(27px at center, var(--var-color-keyboard-key-enabled), #00000000), 
  repeating-linear-gradient(120deg, var(--var-color-keyboard-key-enabled-pattern-01),  
  var(--var-color-keyboard-key-enabled-pattern-01) 1px, 
  transparent 15px, 
  transparent 22px),
  repeating-linear-gradient(60deg,  var(--var-color-keyboard-key-enabled-pattern-01),  
  var(--var-color-keyboard-key-enabled-pattern-01) 1px, 
  transparent 15px, 
  transparent 22px);

}
.unlockable-777765-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}
 
.unlockable-777765-keyboardCap-correct {
  background-color: var(--var-color-correct-green-01);

  background-image:
  radial-gradient(27px at center, var(--var-color-correct-green-01), #00000000), 
  repeating-linear-gradient(120deg, var(--var-color-correct-green-01-pattern-01),  
  var(--var-color-correct-green-01-pattern-01) 1px, 
  transparent 15px, 
  transparent 22px),
  repeating-linear-gradient(60deg,  var(--var-color-correct-green-01-pattern-01),  
  var(--var-color-correct-green-01-pattern-01) 1px, 
  transparent 15px, 
  transparent 22px);
  
}
 
.unlockable-777765-keyboardCap-mistake {
  background-color: var(--var-color-mistake-red-01);

  background-image:
  radial-gradient(27px at center, var(--var-color-mistake-red-01), #00000000), 
  repeating-linear-gradient(140deg, var(--var-color-mistake-red-01-pattern-01),  
  var(--var-color-mistake-red-01-pattern-01) 1px, 
  transparent 15px, 
  transparent 22px),
  repeating-linear-gradient(40deg,  var(--var-color-mistake-red-01-pattern-01),  
  var(--var-color-mistake-red-01-pattern-01) 1px, 
  transparent 15px, 
  transparent 22px);
  
}



@keyframes unlockable-777765-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}

  



/* GRID */


.unlockable-689125-keyboardCap-enabled {
  animation: unlockable-689125-letters-appear  500ms;
  opacity: 0%;
  animation-fill-mode: forwards;

  background-color: var(--var-color-keyboard-key-enabled-pattern-01);

  background-image:
    linear-gradient(var(--var-color-keyboard-key-enabled) 2px, transparent 2px),
    linear-gradient(90deg, var(--var-color-keyboard-key-enabled) 2px, transparent 2px),
    linear-gradient(var(--var-color-keyboard-key-enabled-pattern-01) 1px, transparent 1px),
    linear-gradient(90deg, var(--var-color-keyboard-key-enabled-pattern-01) 1px, transparent 1px);
  background-size: 14px 14px, 14px 14px;
  background-position: -1px 1px, 3px -1px;
} 


.unlockable-689125-keyboardCap-icon {
  transition: filter var(--var-speed-keycap-icon-hover-off);

  background-color: var(--var-color-keyboard-key-enabled-pattern-01);

  background-image:
    linear-gradient(var(--var-color-keyboard-key-enabled) 2px, transparent 2px),
    linear-gradient(90deg, var(--var-color-keyboard-key-enabled) 2px, transparent 2px),
    linear-gradient(var(--var-color-keyboard-key-enabled-pattern-01) 1px, transparent 1px),
    linear-gradient(90deg, var(--var-color-keyboard-key-enabled-pattern-01) 1px, transparent 1px);
  background-size: 14px 14px, 14px 14px;
  background-position: -1px 1px, 3px -1px;

}
.unlockable-689125-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}
 
.unlockable-689125-keyboardCap-correct {
  
  background-color: var(--var-color-correct-green-01-pattern-01);

  background-image:
    linear-gradient(var(--var-color-correct-green-01) 2px, transparent 2px),
    linear-gradient(90deg, var(--var-color-correct-green-01) 2px, transparent 2px),
    linear-gradient(var(--var-color-correct-green-01-pattern-01) 1px, transparent 1px),
    linear-gradient(90deg, var(--var-color-correct-green-01-pattern-01) 1px, transparent 1px);
  background-size: 14px 14px, 14px 14px;
  background-position: -1px 1px, 3px -1px;
  
}
 
.unlockable-689125-keyboardCap-mistake {
  
  background-color: var(--var-color-mistake-red-01-pattern-01);

  background-image:
    linear-gradient(0deg, var(--var-color-mistake-red-01) 2px, transparent 2px),
    linear-gradient(90deg, var(--var-color-mistake-red-01) 2px, transparent 2px),
    linear-gradient(0deg, var(--var-color-mistake-red-01-pattern-01) 1px, transparent 1px),
    linear-gradient(90deg, var(--var-color-mistake-red-01-pattern-01) 1px, transparent 1px);
  background-size: 10px 10px, 10px 10px;
  background-position: -1px 1px, 3px -1px;
  
}


@keyframes unlockable-689125-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}











/* LITERAL */


.unlockable-223388-keyboardCap-enabled {
  animation: unlockable-223388-letters-appear  500ms;
  opacity: 0%;
  animation-fill-mode: forwards;


  background-color: var(--var-color-keyboard-key-enabled-pattern-01);
  
  background-image: url('images/skins/literal-enabled.svg');
  background-size: 33.333%, 33.333%;
  background-position: 0% 50%;

} 


.unlockable-223388-keyboardCap-icon {
  transition: filter var(--var-speed-keycap-icon-hover-off);


  background-color: var(--var-color-keyboard-key-enabled-pattern-01);
  
  background-image: url('images/skins/literal-enabled.svg');
  background-size: 33.333%, 33.333%;
  background-position: 0% 50%;

}
.unlockable-223388-keyboardCap-icon:hover {
  transition: filter var(--var-speed-keycap-icon-hover-on);
  filter: brightness(150%);
}
 
.unlockable-223388-keyboardCap-correct {
  
  background-color: var(--var-color-correct-green-01-pattern-01);


  
  background-image: url('images/skins/literal-correct.svg');
  background-size: 33.333%, 33.333%;
  background-position: 0% 50%;
  
}
 
.unlockable-223388-keyboardCap-mistake {
  
  background-color: var(--var-color-mistake-red-01-pattern-01);


  
  background-image: url('images/skins/literal-mistake.svg');
  background-size: 33.333%, 33.333%;
  background-position: 0% 50%;
  
}



@keyframes unlockable-223388-letters-appear {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}
